<template>
<div class="examin" v-loading="pageLoading">
  
  <div class="result" v-if="info">
    <p>考试详情</p>
    (正确：<span class="green">{{info.on_number}}题</span>  错误：<span class="red">{{info.off_number}}题</span> )
  </div>
  <div class="list">
     <p class="item" :key="index" :class="{red:!item.status}"  v-for="(item,index) in list"  @click="toDetail(index)">
        {{index+1}}
     </p>
  </div>
</div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      pageLoading: true,
      info:null,
      list:[]
    };
  },
  async mounted() {
      this.id = this.$route.query.id
      this.getData();
    
  },
  destroyed() {
    
  },
  computed: {
   
  },
  methods: {
   toDetail(index) {
      this.$router.push({
        path:'./examinList',
        query:{
          currentIndex:index,
          id:this.$route.query.examinId,
          resultId:this.id
        }
      })
   },
    async getData() {
      this.pageLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/flat/test/detail",
        data: {
          test_report_id: this.id,
        },
      });
      this.pageLoading = false;
      if (res.code == 0) {
        this.list = res.data.list;
        this.info = res.data
      } else {
        this.$toast(res.msg);
      }
    },
 
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.examin {
   min-height: calc(100vh - 230px);
  color: #333333;
  width:1200px;
  background: #fff;
  margin: 20px auto;
  padding: 33px;
  box-sizing: border-box;
  .result{
    font-size: 18px;
    p{
      padding-bottom: 10px;
      font-size: 20px;
      color: #333333;
    }
  }
.result .green{
  color: #5FD131;
  margin-right: 14px;
}
.result .red{
  color: #FF4D41;
}
.list{
  padding-top: 30px;
  }
  .list .item{
    float: left;
    width: 32px;
    height: 32px;
    border: 1px solid #A7B3BF;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    margin-right: 33px;
    margin-bottom: 20px;
    font-size: 18px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .list .red{
    background: #FFF1EE;
    border-color: #FFF1EE;
    color: #FF4D41;
  }
}
</style>

<style lang="scss">
</style>
